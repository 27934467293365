<template>
    <div class="cs-block cs-faq-block" :class="`${styleClass}${hasBg}`">
        <div class="cs-base-block">
            <div class="container">
                <CSFaq :data="data" />
            </div>
        </div>
    </div>
</template>

<script>
import {computed} from '../libs/common-fn';

export default {
    name: 'FAQ',
    components: {
        ColorSplit: () => import("../components/CSUtilsColorSplit.vue"),
        CSFaq: () => import("../components/CSFaq.vue"),
    },
    data() {
        return {}
    },
    props: {
        data: {
            type: Object,
            default: () => {}
        }
    },
    computed: {
        ...computed('FAQ'),
        hasBg() {
            if (this.data.style == 'color') return ' has-background';

            return '';
        }
    }
}
</script>

<style lang="scss" scoped>
@import '../styles/main.scss';
.cs-faq-block {
    &.cs-style- {
        &color {
            ::v-deep {
                @include cs-block-color;
                .cs-title {
                    color: white
                }
                .cs-sub-title {
                    color: $sub_title_color;
                }

                .cs-text {
                    color: $text_color_over_theme_color;

                    ::v-deep {
                        * {
                            color: $text_color_over_theme_color !important;
                        }
                    }
                }
                .search-input {
                    &__item {
                        border-color: $text_color_over_theme_color;
                        color: $text_color_over_theme_color;
                    }
                    i {
                        color: $text_color_over_theme_color;
                    }
                }
                .card-header {
                    border-color: $text_color_over_theme_color;
                    color: $text_color_over_theme_color;
                    .arrow {
                        fill: $text_color_over_theme_color;
                    }
                    .dash {
                        background-color: $text_color_over_theme_color;
                    }
                }
                .card-body {
                    p {
                        color: $text_color_over_theme_color;

                        ::v-deep {
                            color: $text_color_over_theme_color;
                        }
                    }
                }
            }
        }

        &light {
            ::v-deep {
                @include cs-block-light;

                .cs-sub-title {
                    color: $sub_title_color_dark;
                }
            }
        }

        &dark {
            ::v-deep {
                @include cs-block-dark;
                .cs-sub-title {
                    color: $sub_title_color_dark;
                }
            }
        }
    }
    
    ::v-deep {
        .header-content {
            text-align: center;
            margin-bottom: $header_margin;
            &:last-child {
                margin-bottom: 0;
            }
            .cs-title, .cs-sub-title {
                margin-bottom: 20px;
            }
            .cs-text {
                ::v-deep {
                    * {
                        text-align: center;
                    }
                    img {
                        max-width: 100%;
                        height: 300px;
                        border-radius: 10px;
                        margin: 10px;
                    }
                    p,
                    span {
                        @include text;
                    }
                    *:last-child {
                        margin-bottom: 0;
                    }
                }
            }
        }
        .search-input {
            margin-top: $theme_items_margin;
            display: block;
            position: relative;
            width: 100%;

            &__item {
                display: block;
                width: 100%;
                height: 40px;
                border: 1px solid #000;
                padding: 25px 15px 25px 40px;
                outline: none;
                border-radius: 30px;
                color: #000;
                background: transparent;
                &::placeholder {
                    color: $labels_color;
                }
            }

            & svg {
                position: absolute;
                left: 2%;
                top: 50%;
                fill: #000;
                transform: translateY(-50%);
                cursor: pointer;
                width: 20px;
                height: 20px;
            }
        }
        .buttons {
            display: flex;
            flex-wrap: wrap;
            gap: 10px;
            margin: $theme_items_margin 0 !important;
            @include text;

            .grey-button,
            .tag-button {
                background-color: #E4EEF7;
                color: $labels_color;
                border: none;
                padding: 10px 20px;
                border-radius: 25px;
                @include base-transition;
                &:hover {
                    opacity: .7;
                }
            }
        }
        .arrow {
            fill: #000;
        }
        .card {
            border-radius: 0;
            border: none;
            background: transparent;
            &.bordered {
                border: 1px solid #000;
                border-radius: 25px;
                margin-bottom: 5px;
                .card-header {
                    border: none;
                }
                .card-body {
                    border-radius: 25px;
                }
            }
        }
        .card-header {
            border-radius: 25px;
            margin-bottom: 10px;
            border: 1px solid #000;
            padding: 15px 15px 15px 20px;
            background-color: transparent;
            cursor: pointer;
            &.hide-button {
                .arrow {
                    display: none;
                }
                .dash {
                    display: block;
                }
            }
        }
        #accordion {
            margin-top: 30px;
        }
        .card-body {
            padding: 20px 20px 20px 30px;
            span {
                ::v-deep {
                    color: $primary_text_color;
                    img {
                        max-width: 100%;
                        height: 300px;
                        border-radius: 10px;
                        margin: 10px 10px 10px 0;
                        object-fit: cover;
                        object-position: center;
                    }
                    &:last-child {
                        margin: 0;
                    }
                }
            }
        }
        .dash {
            height: 4px;
            width: 14px;
            background-color: #000;
            display: none;
        }
    }
}
</style>
